@import '../../styles/variables';
@import '../../styles/mixins';

.formBox {
  p {
    @include StyledItalicP;
  }
  form {
    width: 50%;
  }
}
.setPasswordWrapper {
  min-height: 100vh;
  position: relative;
  background-color: #fff;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
