@import './variables';

@mixin inputContainerWithLabel {
  width: 100%;

  .input-container {
    min-height: 85px;
  }

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  label {
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    color: #787878;
    font-weight: 200;
    display: inline-flex;
  }
  .input-field-wrapper {
    position: relative;
    input {
      @include roundedInput;
      border-left: 5px solid #d5dbdc;
      background-color: #eef0f2;
      margin-top: 5px;
      &:focus {
        border-color: #9eb0ba;
        color: #3f3356;
      }
      &.warning {
        border-color: $color-main-red;
      }
    }
    .input-icon-btn {
      position: absolute;
      top: 17px;
      right: 14px;
    }
  }
  .isInvalid {
    input {
      border-color: $color-main-red;
      color: #4d565a;
      &:focus {
        border-color: $color-main-red;
      }
    }
  }
  .isValid {
    input {
      border-color: $color-main-green;
      color: $color-main-green;
      &:focus {
        border-color: $color-main-green;
      }
    }
  }
  .warning-msg {
    color: $color-main-red;
    margin: 0;
    padding-top: 8px;
    text-align: start;
    font-size: 12px;
  }
  .error-message {
    color: $color-main-red;
    font-size: 10px;
    text-align: left;
    margin-top: 0;
  }
}

@mixin roundedInput {
  text-indent: 10px;
  min-height: 35px;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  outline: none;
  border: none;
  flex-direction: row-reverse;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  background-color: transparent;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus &:-webkit-autofill {
    -webkit-text-fill-color: #000;
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 174, 255, 0.04) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(0, 174, 255, 0.03) 100%
    );
  }
}

@mixin AuthContainer {
  display: flex;
  min-height: 100vh;
  position: relative;
  background-color: #fff;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img-box {
    position: relative;
    width: 50%;
    background-color: #e1e2e3;
    overflow: hidden;
    .yellow-tr {
      position: absolute;
      top: -10%;
      left: 10%;
    }
    .grey-tr-1 {
      position: absolute;
      bottom: 20%;
      left: 60%;
    }
    .grey-tr-2,
    .grey-tr-3 {
      position: absolute;
      bottom: 0;
      left: 50%;
    }
  }
  .green-tr {
    position: absolute;
    bottom: 0;
    right: 33%;
    z-index: 1;
  }
}

@mixin StyledButton {
  width: 100%;
  margin-bottom: 5px;
  height: 39px;
  border-radius: 5px;
  color: #fff;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  line-height: 21px;
  font-size: 14px;
  background-color: #226cad;
  border: none;
  cursor: pointer;
  :focus {
    outline: none;
  }
}
@mixin StyledItalicP {
  font-style: italic;
  font-size: 16px;
  color: #6a717c;
  line-height: 21px;

  a {
    text-decoration: none;
    color: #226cad;
    font-style: italic;
    border: none;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
    }
  }
}

@mixin StyledTable {
  .table-wrapper {
    width: 100%;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

    .title-text {
      color: #6a717c;
      font-style: italic;
      text-align: left;
      margin-right: 55px;
      margin: 30px 55px 30px 0;
      line-height: 24px;
    }
    .create-btn {
      background-color: $color-main-blue;
      border: none;
      color: #fff;
      border-radius: 10px;
      font-size: 16px;
      padding: 10px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    .head_nav {
      color: rgb(106,113,124);
      text-transform: none;
      font-family: 'Ubuntu', sans-serif;

      .head_nav_icon {
        height: 14px;
        margin-right: 5px;
      }
    }
    .head_nav:disabled {
      color: rgb(172,177,182);
    }
  }
}

@mixin StyledCheckbox {
  .checkboxWrapper {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 85px;
    padding-top: 20px;
    box-sizing: border-box;
    label {
      margin-left: 0;
      a {
        margin-left: 5px;
        color: $color-main-blue;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    input[type='checkbox'] {
      display: none;
    }
  }

  input[type='checkbox'] + span {
    margin-left: 5px;
    display: inline-block;
    position: relative;
    width: 13px;
    height: 13px;
    background: white left top no-repeat;
    border: 1px solid #979797;
    border-radius: 2px;
    cursor: pointer;
  }
  input[type='checkbox']:checked + span {
    border: 1px solid $color-main-blue;
    background-image: url('../assets/svgs/check-modal.svg');
    background-position: center;
  }
  input[type='checkbox'] + span {
    margin-right: 10px;
  }
  .warningMsg {
    color: $color-main-red;
    margin: 0;
    padding-top: 8px;
    text-align: start;
    font-size: 12px;
  }
}

@mixin ModalStyledContent {
  text-align: left;
  padding: 30px 30px 20px;
  color: #6a717c;
  h1,
  p {
    margin: 0;
    margin-bottom: 15px;
  }
  h1 {
    color: $color-main-blue;
    font-size: 28px;
  }
  p {
    color: #6a717c;
    font-style: italic;
  }
  footer {
    display: flex;
    justify-content: flex-end;
    // button {
    //   border-radius: 10px !important;
    //   min-width: 100px;
    //   font-size: 18px !important;
    //   color: #fff;
    //   background-color: $color-main-blue;
    //   &:first-child {
    //     margin-right: 10px;
    //     background-color: #d5dbdc;
    //   }
    //   &:last-child {
    //     margin-right: 0;
    //   }
    //   &:disabled {
    //     background: grey;
    //     color: #ddd;
    //     cursor: not-allowed;
    //     &:hover {
    //       opacity: 1;
    //     }
    //   }
    // }
  }
}

@mixin CommonInput {
  color: #4d565a;
  text-indent: 10px;
  min-height: 35px;
  padding-right: 30px;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  outline: none;
  border: none;
  flex-direction: row-reverse;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border-left: 5px solid #d5dbdc;
  background-color: #eef0f2;
  margin-top: 5px;
  box-sizing: border-box;
}

@mixin CommonLabelWithoutRequired {
  width: 100%;
  font-size: 14px;
  color: #4d565a;
  font-weight: 200;
  display: inline-flex;
}

@mixin CommonLabel {
  width: 100%;
  font-size: 14px;
  color: #4d565a;
  font-weight: 200;
  display: inline-flex;
  &::after {
    content: '*';
    color: $color-main-red;
    font-weight: bold;
  }
}

@mixin RadioInput {
  input[type='radio']:after {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    top: 0;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ccc;
    margin-right: 5px;
  }
  input[type='radio']:checked:after {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    top: 0;
    left: -1px;
    position: relative;
    background-color: $color-main-blue;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ccc;
    margin-right: 5px;
  }
}

// conditions array or fields
@mixin ArrayOfFields {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 15px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    border: none;

    width: 35px;
    height: 30px;
    margin: 0 5px 0 0;

    color: #4d565a;
    background-color: #eef0f2;

    cursor: pointer;
  }

  i {
    top: 10px;
  }

  input {
    margin: 0 !important;
  }

  & > div {
    width: 100%;
  }
}

@mixin SlateStyles {
  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

  code {
    display: inline-block;
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
  }

  .align_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .align_justify {
    display: flex;
    flex-direction: column;
    text-align: justify;
  }

  .align_center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .align_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .table-wrapper-border {
    border: 1px solid black;

    & > table {
      margin: 0;
    }
  }

  table {
    width: 100%;
    max-width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    border-top: 1px solid black;

    p {
      margin: 0;
    }
  }

  table tr {
    border: none;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }

  table thead tr {
    background: #f5f5f5;
    font-weight: bold;
  }

  table td,
  th {
    border: 1px solid black;
    // border-top: none;
    border-bottom: none;
    border-right: none;
    padding: 0.5em;
    position: relative;
    min-width: 20px;
  }

  /* for nested tables */
  table td > table {
    width: 100%;
  }

  td > p:first-child {
    margin-top: 0;
  }

  td > p:last-child {
    margin-bottom: 0;
  }
}
