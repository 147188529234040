@import '../../../styles/mixins';

// setup styles of Slate
.text-editor {
  @include SlateStyles;
}

$loop-border-color: rgb(34, 185, 173);
$loop-background-color: rgb(233, 248, 247);
$filter-border-color: rgb(201,	157,	58);
$filter-background-color: rgb(247, 243, 236);
$reference-border-color: rgb(81, 146, 203);
$reference-background-color: rgb(233, 240, 247);
$form-border-color: rgb(179,	151,	11);
$form-background-color: rgb(251,	242,	191);

button {
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
}

.text-editor {
  width: 95%;
  margin: 30px auto;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 10px;
}

.editor-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;

  .btn-container {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 30px auto 0;

    .settings-btn-section {
      button {
        background-color: #c3c6c9;
        color: #3d4655;
        margin-right: 0;
      }
    }
  }
}

.settings {
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  width: 75%;
}

.reference {
  display: inline-block;
  background-color: $reference-background-color;
  border-color: $reference-border-color;
  border-width: 1px 5px;
  border-style: solid;
  color: $reference-border-color;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}

.field {
  display: inline-block;
  background-color: $reference-background-color;
  border-color: $reference-border-color;
  border-width: 1px 5px;
  border-style: solid;
  color: $reference-border-color;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}

.loop {
  display: block;
  background-color: $loop-background-color;
  border-color: $loop-border-color;
  border-width: 1px 5px;
  border-style: solid;
  color: $loop-border-color;
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px;
  cursor: pointer;

  .loop {
    margin: 5px;
  }

  table {
    margin: 5px 0;

    .field {
      border: 1px solid black;
      margin: 0 5px 0 0;
    }
  }
}

.condition {
  display: block;
  background-color: $filter-background-color;
  border-color: $filter-border-color;
  border-width: 1px 5px;
  border-style: solid;
  color: $filter-border-color;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;

  & .condition {
    margin-left: 15px;
    margin-bottom: 10px;
  }
}

.form {
  display: inline-block;
  background-color: $form-background-color;
  border-color: $form-border-color;
  border-width: 1px 5px;
  border-style: solid;
  color: $form-border-color;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}

.form-preview {
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  align-items: center;
  width: 50%;
  input,
  select {
    @include CommonInput;
    margin-bottom: 15px;
  }
  label {
    @include CommonLabelWithoutRequired;
  }
  label.required {
    @include CommonLabel;
  }
  button {
    @include StyledButton;
  }
}

.editor-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .slate-editor {
    // border: 1px solid #ccc;
    border-radius: 2px;
    min-height: 200px;
    padding: 8px;
    text-align: left;
    color: rgb(106,113,124);
  }
}

.editor-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .slate-editor {
    // border: 1px solid #ccc;
    border-radius: 2px;
    min-height: 200px;
    padding: 8px;
    text-align: left;

    .loop-table {
      box-shadow: 0px 0px 0px 1px #000000;
    }
  }
}

.raw-editor {
  display: none;
  min-height: 243px;
  width: calc(100% - 6px);
  max-width: calc(100% - 6px);
}

.showRaw .slate-editor {
  display: none;
}

.showRaw .raw-editor {
  display: block;
}

.separator {
  width: 3px;
  color: gray;
  margin-right: 5px;

  &:after {
    content: '•';
  }
}

// table buttons
.additionalButtons {
  display: none;
  margin-top: 32px;
  position: absolute;
  background-color: #eaecec;
  border-radius: 5px;
}

.buttons {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  padding: 2px 3px;
  background-color: #eaecec;
  z-index: 100;

  &:hover {
    .additionalButtons {
      display: block;
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin: 2px 0;
    z-index: 100;
    padding: 5px;
    width: 118px;
    height: 30px;
    font-family: 'Ubuntu', sans-serif;

    &:hover {
      color: #06c;
    }
  }
}

.insertTableButton {
  button {
    border: none;
    font-family: 'Ubuntu', sans-serif;
  }
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

// setup styles of Slate
.slate-editor {
  p {
    margin: 5px 0;
  }
}

.raw-editor {
  display: none;
  min-height: 200px;
  width: calc(100% - 6px);
}

.showRaw .ql-editor {
  display: none;
}

.showRaw .raw-editor {
  display: block;
}

.btn-with-icon {
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
}

.save-btn-section,
.settings-btn-section {
  display: flex;
}
