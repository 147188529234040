@import '../../../styles/mixins';

.fieldContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    @include CommonLabel;
  }

  .inputRedirect {
    @include CommonInput;
    margin: 10px 0 15px 0;
  }

  i {
    color: #226cad;
    position: absolute;
    top: 35px;
    right: 10px;
    cursor: pointer;
  }
}
