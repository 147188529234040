@import '../../../styles/mixins';

.previewModal {
  position: relative;
  text-align: left;
  header {
    display: flex;
    justify-content: center;
    h3 {
      margin: 15px;
      color: #3d4655;
      font-weight: 400;
    }
    img {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 15px;
      cursor: pointer;
    }
  }

  .mainPreview {
    display: flex;
    flex-direction: column;
    padding: 20px;
    pointer-events: none;
    .loop-table {
      text-align: center;
    }

    li {
      margin: 0 20px;
    }

    @include SlateStyles;
  }
}
